import React, {  useState,useEffect } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import {productCatalogS3Bucket} from "../config";

import ServiceAddress from "./new-pages/common/ServiceAddress";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";
import bubbleIcon from "../../static/images/speech-bubble.png";
import tvImg1 from "../../static/images/tv-1.jpg";
import tvImg2 from "../../static/images/tv-2.png";
import tv3Icon1 from "../../static/images/tv-sec-icon1.png";
import tv3Icon2 from "../../static/images/tv-sec-icon2.png"; 
import tv3Icon3 from "../../static/images/tv-sec-icon3.png";
import tvImg3 from "../../static/images/tv-3.jpg";
import tvImg7 from "../../static/images/sec7-vector-right.png";
import CommonQuestionTV from "./new-pages/common/CommonQuestionTV";
import axios from "axios";
import {calculateYearlyPricePerMonth} from "../utils";

const FrontTVPage = () => {
    const ignoreList = ["Add 50hrs of DVR", "Pick 5 Channels", "Single Channels"]
    const [showModal, setShowModal] = useState(false);
    const [tabType, setTabType] = React.useState(false);
    const [messagePopup, setMessagePopup] = React.useState(false);
    const [includedChannles, setIncludedChannles] = React.useState([]);
    /**
     * get included channels for popup
     */

    const getChannelImageUrls = (channels: any) => {
        setIncludedChannles(channels.filter(obj => obj.status === "ACTIVE").map(obj => obj.image_url))
        setShowModal(true);
    }

    const [plans, setPlans] = useState([]);

    const [addons, setAddons] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const plansResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/plans.json"
                );
                const addonsResponse = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/tv/addons.json"
                );
                setPlans(plansResponse.data);
                setAddons(addonsResponse.data);
            } catch (err) {
                console.error("Failed to fetch internet plans:", err);
            }
        };
        fetchData();
    },[])

    return (
        <LayoutNew>
            <Helmet>
                <title>TV – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <section className="tv-section1">
                <div className="container mx-auto px-4">
                    <h1 className="h2">Amazing TV, without the price</h1>
                    <img src={tvImg1} alt="Purple Cow Internet TV, cheap TV" className="tv-banner-img" />
                </div>
            </section>
            
            <div className="container mx-auto">
                <section className="tv-section2 gray-box">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="section2-text">
                            <h3 className="h3">The familiarity you're used to, with the ability to watch TV on most of your existing devices in any room.</h3>
                            <p className="p2">We built our TV app from the ground up to make it super simple to use and setup. Quite simply just download the Purple Cow TV app, put in your password and you're watching TV.</p>
                            <p className="p2">Use any Apple, Android, or firestick device and soon just in your computer browser.</p>
                        </div>
                        <div className="">
                            <img src={tvImg2} alt="TV" className="tv-sec2-img" />
                        </div>
                    </div>
                </section>
            </div>

            <section className="tv-section3-new">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-center text-secondary">All your channels and features</h2>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
                        <div className="text-center">
                            <div className="tv-sec3-box">
                                <img src={tv3Icon1} alt="TV" className="tv-sec3-icon" />
                            </div>
                            <h5 className="h5">Record Live TV</h5>
                            <p className="p2">Record Live TV and store in the cloud</p>
                        </div>
                        <div className="text-center">
                            <div className="tv-sec3-box">
                                <img src={tv3Icon2} alt="TV" className="tv-sec3-icon" />
                            </div>
                            <h5 className="h5">Pause Live TV</h5>
                            <p className="p2">Pause and Replay across all devices</p>
                        </div>
                        <div className="text-center">
                            <div className="tv-sec3-box">
                                <img src={tv3Icon3} alt="TV" className="tv-sec3-icon" />
                            </div>
                            <h5 className="h5">Use Existing Device</h5>
                            <p className="p2">No need for a clunky box in each room..</p>
                        </div>
                    </div>
                </div>                
            </section>

            <section className="tv-section4 mt-20">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center ">Same channels as the big guys</h2>
                    <p className="p2 text-black text-center mb-8">Only less expensive ;)</p>
                    <div className="tab-type new-tab">
                        <span onClick={() => setTabType(false)} className={tabType ? "tab-type__option " : "tab-type__option active"}>Pay Monthly</span>
                        <span onClick={() => setTabType(true)} className={tabType ? "tab-type__option active" : "tab-type__option "}>Pay Yearly</span>
                    </div>
                    <div className="selectPlan addon-plan-1">
                        {plans.map((plan) => (
                            plan.status != "ACTIVE" ? null : (
                                <div className="selectPlan__optional">
                                    <label className="selectPlan__box">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            value={'item.addon.id'}
                                            checked={false}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{plan.name}</h3>
                                            <h2 className="h2"
                                                dangerouslySetInnerHTML={{__html: !tabType ? '$' + plan.billing_period[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + plan.billing_period[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(plan.billing_period[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                            </h2>
                                            <div className="chennels-box">
                                                {plan.featured_channels.map((channel) => ( /* slice(0, 6) gets the first 6 elements*/
                                                    /* TODO: Decide which channels go here + add status check if necessary */
                                                    channel.status != "ACTIVE" ? null : (
                                                        <img src={channel.image_url} alt="channels" className="channel-icon"/>
                                                    )
                                                ))}
                                            </div>
                                            <p className="p4">{plan.description}</p>
                                            <span className="a-link-bold" onClick={() => getChannelImageUrls(plan.included_channels)}>
                                            View Included Channels
                                            </span>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>
                </div>
                <div className="container mx-auto px-4">
                    <h4 className="h2 text-secondary text-center ">Add additional packs</h4>


                    <div className="selectPlan addon-plan-1">
                        {addons.map((addon) => (
                            (ignoreList.includes(addon.name) || addon.status != "ACTIVE") ? null : (
                                <div className="selectPlan__optional">
                                    <label className="selectPlan__box">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            value={'item.addon.id'}
                                            checked={false}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{addon.name}</h3>
                                            <h2 className="h2"
                                                dangerouslySetInnerHTML={{__html: !tabType ? '$' + addon.billing_period[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + addon.billing_period[0].monthly.price + '</del> $' + calculateYearlyPricePerMonth(addon.billing_period[0].yearly.price) + ' <sup>/ month</sup>'}}>
                                            </h2>
                                            <div className="chennels-box">
                                                {addon.included_channels.map((channel) => (
                                                    channel.status != "ACTIVE" ? null : (
                                                        <img src={channel.image_url} alt="channels" className="channel-icon"/>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>

                </div>
                <div className="container mx-auto px-4">
                    <h4 className="h2 text-secondary text-center ">Add single channels</h4>
                    <p className="p2 bottom">Add any additional channels for $4 each or 5 you want for only $15. This
                        can be added after checkout with the team.</p>

                    <div className="selectPlan addon-plan-1">
                        {addons.filter(obj => obj.name == "Single Channels").map((addon) => (
                            <label className="selectPlan__box_wide">
                                <div className="selectPlan__box-inner">
                                    <div className="chennels-box-wide">
                                        {addon.included_channels.map((channel) => (
                                            channel.status != "ACTIVE" ? null : (
                                                <img src={channel.image_url} alt="channels" className="channel-icon-single"/>
                                            )
                                        ))}
                                    </div>
                                </div>
                            </label>
                        ))}

                            </div>
                            <p className="p2 bottom">Not seeing a channel you want? Let us know and we will see if we can get it for you!</p>
                            </div>
                            </section>

                            <ServiceAddress title="Check your service address" />

                            <section className="tv-section2 ">
                            <div className="container mx-auto px-4">
                            <div className="grid sm:grid-cols-1 md:grid-cols-2 sm:gap-4 md:gap-8">
                            <div className="section2-text">
                            <h3 className="h3">Say goodbye to clunky TV boxes & use your existing box or Smart TV</h3>
                            <p className="p2">No need to pay to rent a TV box or return it when you are done. No need to keep track of an extra remote or run wires through your home.</p>
                            <p className="p2">We keep it simple by letting you use your existing TV streaming box.</p>
                            </div>
                            <div className="">
                            <img src={tvImg3} alt="TV" className="tv-sec2-img"/>
                </div>
            </div>
        </div>
</section>

    <section className="tv-section7">
        <div className="container mx-auto px-4">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                <div className="section7-right-content">
                    <img src={tvImg7} alt=""/>
                </div>
                <div className="section7-text">
                    <h3 className="h3">Start, stop, pause live TV & record your favorite shows in the cloud</h3>
                    <p className="p2">Purple Cow TV includes free PVR to Pause live TV.</p>
                    <p className="p2">Add DVR for $5 a month to record up to 50 hours of content that can be watched on
                        any of your devices from any room.</p>
                </div>
            </div>
        </div>
    </section>

    <CommonQuestionTV/>

    <HaveQuestion closepopup={setMessagePopup}/>
    <LastSection title="Or if you're ready..."/>

    <div className="contact-us-parent">
        <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
            <img src={bubbleIcon} alt="bubble" className="bubble-icon"/>
        </div>
    </div>
{
    messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null
}
{
    showModal ? (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    {/*content*/}
                    <div
                        className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <button
                            className="ml-auto mt-0 bg-transparent border-0 text-white leading-none outline-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                        >
                                    <span
                                        className="bg-transparent opacity-25 text-black h-6 w-6 text-4xl block outline-none focus:outline-none mr-2">
                                        ×
                                    </span>
                        </button>
                        <div className="text-center">
                            <h4 className="text-1xl text-gray-600">Included Channels</h4>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="#47525E"
                                className="w-6 h-6 m-auto cursor-pointer"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                            </svg>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 chennel-modal-parent">
                            <div className="chennels-box">
                                {includedChannles.map((item, index) => {
                                    return (
                                        <>
                                            <div className="chennels-box__icon" key={index + 's'}>
                                                <img src={item}></img>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : null
}
</LayoutNew>
)
}

export default FrontTVPage
 
